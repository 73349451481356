import React from "react"
import PropTypes from "prop-types"

import Card from "../atoms/Card"
import { Paragraph } from "../atoms/Typography"

const TweetCard = ({ bg, boxShadow, time, content, link }) => {
  return (
    <a
      style={{ textDecoration: "none" }}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Card
        bg={bg ? bg : undefined}
        boxShadow={boxShadow ? boxShadow : undefined}
        px={4}
        py={6}
        marginX="auto"
        maxWidth={30}
        height="100%"
      >
        <Paragraph mb={4}>{time}</Paragraph>
        <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
      </Card>
    </a>
  )
}

TweetCard.propTypes = {
  bg: PropTypes.number,
  boxShadow: PropTypes.string,
  time: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default TweetCard
