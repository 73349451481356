import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import Icon from "../atoms/Icon"

import SectionTitle from "../molecules/SectionTitle"

const ThinCta = ({ section, sectionTitle, button, icon }) => {
  return (
    <Section {...section} verticalPadding={"custom"} customVerticalPadding={30}>
      <Reveal cascade>
        <Flex
          flexDirection={{ _: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex
            alignItems={"center"}
            alignSelf={"flex-start"}
            mb={{ _: 5, md: 0 }}
          >
            {icon && (
              <Icon
                flexShrink={0}
                {...icon}
                mr={5}
                height={"36px"}
                width={"36px"}
              />
            )}
            {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={42} />}
          </Flex>
          {button && <Button {...button} ml={{ _: 0, md: 5 }} />}
        </Flex>
      </Reveal>
    </Section>
  )
}

export default ThinCta

export const query = graphql`
  fragment ThinCta on Strapi_ComponentSectionsThinCta {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    icon {
      ...Icon
    }
  }
`
