import styled from "styled-components"
import css from "../../lib/styled-system/css"
import MuiInputLabel from "@material-ui/core/InputLabel"

const StyledInputLabel = styled(MuiInputLabel)`
  && {
    ${css({ fontFamily: "body", color: "text" })}
    margin-bottom: 6px;
    font-size: 12px;
  }
`

export default StyledInputLabel
