import React from "react"
import Box from "../atoms/Box"

const Form = (props) => <Box as="form" {...props} />

Form.propTypes = {
  ...Box.propTypes,
}

export default Form
