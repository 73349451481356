import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"
import Link from "../utils/Link"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"

import SectionTitle from "../molecules/SectionTitle"
import DraggableCarousel from "../molecules/DraggableCarousel"

const LogoCarousel = ({
  section,
  sectionTitle,
  logoItems,
  rotateSpeed,
  button,
}) => {
  return (
    <Section {...section} noStandardPadding>
      <StandardPadding
        px={0}
        verticalPadding={section.verticalPadding}
        position="relative"
        zIndex={10}
      >
        {sectionTitle && (
          <StandardPadding py={0}>
            <Reveal>
              <SectionTitle {...sectionTitle} mb={7} maxWidth={42} />
            </Reveal>
          </StandardPadding>
        )}
        {logoItems && (
          <DraggableCarousel
            rotateSpeed={rotateSpeed}
            numItems={logoItems.length}
          >
            {logoItems.map(({ name, link, image, color }, i) => {
              const key = `${name}-${i}`
              return (
                <Link key={key} {...link}>
                  <Flex
                    boxShadow="md"
                    justifyContent="center"
                    bg={color}
                    width={{ _: "240px", sm: "280px", md: "342px" }}
                    height={{ _: "276px", sm: "322px", md: "394px" }}
                  >
                    <Image
                      draggable="false"
                      style={{ width: "65%", height: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      {...image}
                      alt={name}
                    />
                  </Flex>
                </Link>
              )
            })}
          </DraggableCarousel>
        )}
        {button && (
          <Reveal>
            <Flex justifyContent="center" mt={7}>
              <Button {...button} />
            </Flex>
          </Reveal>
        )}
      </StandardPadding>
    </Section>
  )
}

export default LogoCarousel

LogoCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  logoItems: PropTypes.arrayOf(PropTypes.object),
  button: PropTypes.shape(Button.strapiProps).isRequired,
}

export const query = graphql`
  fragment LogoCarousel on Strapi_ComponentSectionsLogoCarousel {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    logoItems {
      name
      ...Logo
    }
    button {
      ...Button
    }
    rotateSpeed
  }
`
