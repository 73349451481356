import React from "react"
import styled from "styled-components"
import { space, layout, border, compose } from "styled-system"
import css from "../../lib/styled-system/css"
import { splitProps, shouldForwardProp } from "../../lib/styled-system/helpers"

import NativeSelect from "@material-ui/core/NativeSelect"
import InputLabel from "../atoms/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"

const Wrapper = styled("div").withConfig({ shouldForwardProp })`
  ${compose(space, layout)}

  & .MuiInputBase-root {
    width: 100%;
    height: 48px;
  }

  & .MuiNativeSelect-root,
  & .MuiNativeSelect-select:focus {
    ${css({ fontFamily: "body", color: "text" })}
    width: 100%;
    height: 100%;
    padding: 8px 10px 8px 6px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    ${css({
      fontSize: 1,
      borderRadius: "sm",
      borderColor: "border.1",
    })}
    ${border}
  }
  & .MuiInput-root.Mui-error {
    & .MuiNativeSelect-root {
      border-color: #f44336;
    }
  }
  & .MuiNativeSelect-icon {
    margin-right: 4px;
  }
  & .MuiFormHelperText-root {
    ${css({ fontFamily: "body" })}
  }
`

const Select = (props) => {
  let { space, layout, border, rest: selectProps } = splitProps(props, [
    "space",
    "layout",
    "border",
  ])

  const {
    id,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
    error,
    helperText,
    required,
    ...rest
  } = selectProps

  return (
    <Wrapper {...space} {...layout} {...border}>
      {label && (
        <InputLabel htmlFor={id} error={error} required={required}>
          {label}
        </InputLabel>
      )}
      <NativeSelect
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        required={required}
        aria-describedby={`${id}-helper-text`}
        disableUnderline
        {...rest}
      >
        {options.map((options) => (
          <option value={options.value}>{options.label}</option>
        ))}
      </NativeSelect>
      {helperText && (
        <FormHelperText id={`${id}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Wrapper>
  )
}

export default Select
