import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { compose, layout } from "styled-system"
import shave from "shave"
import useDimensions from "react-use-dimensions"
import { animation } from "../../lib/styled-system/system"

import Link from "../utils/Link"
import Image from "../utils/Image"
import { hexToRGBA } from "../../lib/color-helpers"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import { Title } from "../atoms/Typography"
import RichText from "../atoms/RichText"

const TextBox = styled(
  ({
    title,
    descriptionRichText,
    className,
    textClassName,
    textAlign,
    ...rest
  }) => {
    return (
      <Box
        display={textAlign === "center" ? "flex" : "block"}
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        textAlign={textAlign === "center" ? "center" : "left"}
        className={className}
        {...rest}
      >
        <Title
          transitionProperty="opacity"
          transitionTimingFunction="ease"
          transitionDuration="md"
          className={textClassName}
          variant="h3"
          mb={3}
        >
          {title}
        </Title>
        {descriptionRichText?.content && (
          <StyledRichText
            transitionProperty="opacity"
            transitionTimingFunction="ease"
            transitionDuration="md"
            className={textClassName}
            textAlign={textAlign}
            {...descriptionRichText}
          />
        )}
      </Box>
    )
  }
)`
  ${animation}
`

const StyledLink = styled(Link)`
  &:hover {
    & .image {
      transform: scale(1.05);
    }
  }
`

const StyledCard = styled(Card)`
  & .text-box {
    opacity: ${props => (props.textVisibility !== "onHover" ? 1 : 0)};
  }
  &:hover {
    & .text-box {
      ${props => (props.textVisibility === "onHover" ? "opacity: 1" : null)};
    }
    & .shaved-text {
      opacity: ${props => (props.textVisibility === "onHover" ? 1 : 0.75)};
    }
  }
`

const StyledRichText = styled(RichText)`
  &.shaved-text {
    & .RichEditor-unstyled {
      margin-bottom: 100px; //hiding richText children that appear below the first container
    }
  }
  ${animation}
`

const StyledImage = styled(Image)`
  ${compose(layout)}
`

const defaultCard = {
  borderRadius: "none",
  boxShadow: "md",
  bg: 0,
  theme: "light",
  useCard: false,
}

const ImageCard = ({
  title,
  descriptionRichText,
  image,
  link,
  alignText,
  card,
  textBackground,
  textVisibility,
}) => {
  //setting a default value for card prop
  card =
    card && card.constructor === Object && Object.keys(card).length > 0
      ? card
      : defaultCard

  const currentTheme = useContext(ThemeContext)
  const bgColor = card
    ? currentTheme?.themes[card?.theme]?.colors?.background[card?.bg]
    : "#FFFFFF"
  const bgStartColor = hexToRGBA(bgColor, 100)
  const bgMidColor = hexToRGBA(bgColor, 90)
  const bgEndColor = hexToRGBA(bgColor, 0)
  const [richTextRef, richTextSize] = useDimensions()

  const showText = textVisibility !== "none" && card.useCard && bgColor
  const showGradient = textBackground === "gradient" && showText
  const hasDescription = descriptionRichText.content.blocks[0].text.length
    ? true
    : false

  useEffect(() => {
    const pFontSizePos = currentTheme.typography.p.fontSize
    let themePFontSize = currentTheme.fontSizes[pFontSizePos]
    shave(
      ".shaved-text span",
      richTextSize.height < 246
        ? Math.ceil(themePFontSize * 3)
        : richTextSize.height < 267
        ? Math.ceil(themePFontSize * 2.5)
        : richTextSize.height < 300
        ? themePFontSize * 5
        : richTextSize.height < 365
        ? Math.ceil(themePFontSize * 4)
        : Math.ceil(themePFontSize * 6)
    )
  }, [richTextSize?.height])

  return (
    <StyledLink optional {...link}>
      <StyledCard
        {...card}
        bg={null} //avoiding card to set bg color
        ratio={1}
        position="relative"
        textVisibility={textVisibility}
        textBackground={textBackground}
      >
        {image && (
          <>
            <Box
              transitionProperty="height, transform"
              transitionTimingFunction="ease"
              transitionDuration="md"
              height={
                showText &&
                textBackground === "solid" &&
                textVisibility === "visible" &&
                (title || hasDescription)
                  ? {
                      _: "calc(100% - 128px)",
                      xs: "calc(100% - 150px)",
                      sm: "calc(100% - 170px)",
                      md: "calc(100% - 115px)",
                      lg: "calc(100% - 128px)",
                    }
                  : "100%"
              }
              minHeight={`${richTextSize.height * 0.6}px`}
              className="image"
            >
              <StyledImage
                {...image}
                style={{ height: "100%" }}
                minHeight={!card?.useCard ? "358px" : "auto"}
              />
            </Box>
            <Box
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              position="absolute"
              opacity={showGradient ? 1 : 0}
              top={0}
              left={0}
              bottom={0}
              right={0}
              background={`linear-gradient(to top, ${bgStartColor} 0%,${bgMidColor} 30%, ${bgEndColor}) 100%`}
            />
          </>
        )}

        {showText && (title || hasDescription) && (
          <div ref={richTextRef} style={{ height: "100%" }}>
            <TextBox
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              textAlign={alignText === "center" ? "center" : "left"}
              textClassName="shaved-text"
              className="text-box"
              title={title}
              descriptionRichText={descriptionRichText}
              bg={
                textBackground === "gradient"
                  ? "transparent"
                  : `background.${card.bg}`
              }
              position="absolute"
              width="100%"
              height={{
                _: 8,
                xs: "150px",
                sm: "170px",
                md: "115px",
                lg: 8,
              }}
              left="0px"
              bottom="0px"
              overflow="hidden"
              maxHeight={`${richTextSize.height * 0.4}px`}
              minHeight={`${richTextSize.height * 0.2}px`}
              py={4}
              px={5}
            />
          </div>
        )}
      </StyledCard>
    </StyledLink>
  )
}

export default ImageCard

ImageCard.strapiProps = {
  title: PropTypes.string,
  description: PropTypes.shape(RichText.strapiProps),
  image: PropTypes.object.isRequired,
}

ImageCard.propTypes = {
  ...ImageCard.strapiProps,
  cardBackground: PropTypes.oneOf(["none", "0", "1"]),
  cardShadow: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment ImageCard on Strapi_ComponentMoleculesImageCard {
    title
    descriptionRichText {
      ...RichText
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    link {
      ...Link
    }
  }
`
