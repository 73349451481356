import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import TweetCard from "../molecules/TweetCard"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import Box from "../atoms/Box"

import SwipeableViews from "../molecules/SwipeableViews"

import SectionTitle from "../molecules/SectionTitle"

// TODO ADD PROPTYPES

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

function getTimeString(inputDate) {
  return MONTHS[inputDate.getMonth()] + " " + inputDate.getDate()
}

//alters text so RTs aren't truncated
function getTweetHTML(tweetObject) {
  if (!tweetObject.retweeted_status) {
    return editEntities(tweetObject.full_text, tweetObject.entities)
  } else {
    let retweetObject = tweetObject.retweeted_status
    return `RT <a href=https://twitter.com/${
      retweetObject.user.screen_name
    } target="_blank" rel="noopener noreferrer">@${
      retweetObject.user.screen_name
    }</a>: ${editEntities(retweetObject.full_text, retweetObject.entities)}`
  }
}

//Add <a> tags to entity urls, mentions, and hashtags, and remove links to media urls
function editEntities(fullText, entities) {
  let newText = fullText
  if (entities.urls) {
    entities.urls.forEach(url => {
      newText = newText.replace(
        url.url,
        `<a href=${url.url} target="_blank" rel="noopener noreferrer">${url.display_url}</a>`
      )
    })
  }
  if (entities.media) {
    entities.media.forEach(media => {
      newText = newText.replace(media.url, "")
    })
  }
  if (entities.user_mentions) {
    entities.user_mentions.forEach(user => {
      newText = newText.replace(
        `@${user.screen_name}`,
        `<a href=https://twitter.com/${user.screen_name} target="_blank" rel="noopener noreferrer">@${user.screen_name}</a>`
      )
    })
  }
  if (entities.hashtags) {
    entities.hashtags.forEach(hashtag => {
      newText = newText.replace(
        `#${hashtag.text}`,
        `<a href=https://twitter.com/hashtag/${hashtag.text} target="_blank" rel="noopener noreferrer">#${hashtag.text}</a>`
      )
    })
  }
  return newText
}

const Twitter = props => {
  const tweetCards = () =>
    props.tweets.slice(0, 3).map((tweet, i) => {
      let tweetDate = new Date(tweet.node.created_at)
      return (
        <TweetCard
          key={i}
          bg={`background.${props.twitterCardsBackground}`}
          boxShadow={props.twitterCardsShadow}
          time={getTimeString(tweetDate)}
          content={getTweetHTML(tweet.node)} //tweet.node.full_text, //.split(`http`)[0],
          link={`https://twitter.com/flybridge/status/${tweet.node.id_str}`}
        />
      )
    })

  return (
    <Section {...props.section}>
      {props.sectionTitle && (
        <SectionTitle {...props.sectionTitle} mb={6} maxWidth={42} />
      )}
      <Box display={{ _: "none", lg: "block" }}>
        <FlexGrid {...props.flexGrid}>{tweetCards()}</FlexGrid>
      </Box>
      <Box display={{ _: "block", lg: "none" }}>
        <SwipeableViews arrows indicators>
          {tweetCards()}
        </SwipeableViews>
      </Box>
      {props.button && (
        <Flex mt={6} alignItems="center" flexDirection="column">
          <Button {...props.button} />
        </Flex>
      )}
    </Section>
  )
}

Twitter.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  flexGrid: PropTypes.shape(FlexGrid.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps),
  twitterCardsBackground: PropTypes.number,
  twitterCardsShadow: PropTypes.string,
}

Twitter.propTypes = {
  ...Twitter.strapiProps,
  data: PropTypes.object.isRequired,
}

const StaticQueryTwitter = props => (
  <StaticQuery
    query={graphql`
      query {
        allTwitterStatusesUserTimelineTwitterQuery {
          edges {
            node {
              created_at
              full_text
              id_str
              entities {
                urls {
                  display_url
                  url
                }
                media {
                  url
                }
                user_mentions {
                  id_str
                  screen_name
                }
                hashtags {
                  text
                }
              }
              retweeted_status {
                full_text
                user {
                  screen_name
                }
                entities {
                  urls {
                    display_url
                    url
                  }
                  media {
                    url
                  }
                  user_mentions {
                    id_str
                    screen_name
                  }
                  hashtags {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Twitter
        tweets={data?.allTwitterStatusesUserTimelineTwitterQuery?.edges}
        {...props}
      />
    )}
  />
)

export default StaticQueryTwitter

export const query = graphql`
  fragment Twitter on Strapi_ComponentSectionsTwitter {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    flexGrid {
      ...FlexGrid
    }
    twitterCardsBackground: cardsBackground
    twitterCardsShadow: cardsShadow
  }
`
