import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"

import Stat from "../molecules/Stat"
import SectionTitle from "../molecules/SectionTitle"

const Stats = ({ section, sectionTitle, flexGrid, stats, statsAlign }) => {
  let newFlexGrid = { ...flexGrid }
  if (flexGrid.columns === 4) {
    newFlexGrid.columns = { _: 1, sm: 2, lg: 4 }
  } else if (flexGrid.columns === 3) {
    newFlexGrid.columns = { _: 1, md: 3 }
  } else if (flexGrid.columns === 2) {
    newFlexGrid.columns = { _: 1, sm: 2 }
  }
  newFlexGrid.gutter = { _: 7, lg: 8 }

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={8} maxWidth={42} />
        </Reveal>
      )}
      <div>
        <FlexGrid {...newFlexGrid}>
          {stats.map((stat, i) => (
            <Stat {...stat} align={statsAlign} key={i} />
          ))}
        </FlexGrid>
      </div>
    </Section>
  )
}

export default Stats

Stats.defaultProps = {
  statsAlign: "left",
}

Stats.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  stats: PropTypes.arrayOf(PropTypes.shape(Stat.strapiProps)),
  statsAlign: PropTypes.oneOf(["left", "center"]),
}

export const query = graphql`
  fragment Stats on Strapi_ComponentSectionsStats {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    stats {
      ...Stat
    }
    statsAlign
  }
`
