import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Card from "../atoms/Card"

import SectionTitle from "../molecules/SectionTitle"
import ImageCard from "../molecules/ImageCard"

const ImageGrid = ({
  section,
  sectionTitle,
  flexGrid,
  imageCards,
  card,
  textBackground = "solid", // enums: solid, gradient
  textVisibility = "visible", //enums: none, onHover, visible,
  alignText = "center",
}) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FlexGrid {...flexGrid}>
        {imageCards &&
          imageCards.map((imageCard, i) => {
            return (
              <ImageCard
                key={i}
                {...imageCard}
                alignText={alignText}
                card={card}
                textVisibility={textVisibility}
                textBackground={textBackground}
              />
            )
          })}
      </FlexGrid>
    </Section>
  )
}

export default ImageGrid

ImageGrid.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  imageCards: PropTypes.arrayOf(PropTypes.shape(ImageCard.strapiProps)),
  alignText: PropTypes.oneOf(["left", "center"]),
  textVisibility: PropTypes.oneOf(["none", "onHover", "visible"]),
  textBackground: PropTypes.oneOf(["solid", "gradient"]),
}

ImageGrid.propTypes = {
  ...ImageGrid.strapiProps,
}

export const query = graphql`
  fragment ImageGrid on Strapi_ComponentSectionsImageGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    imageCards {
      ...ImageCard
    }
    alignText
    textVisibility
    textBackground
  }
`
