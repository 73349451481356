import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Box from "../atoms/Box"

const StyledImg = styled("img")({
  height: "100%",
  width: "100%",
  objectFit: "contain",
})

/*
 * An SVG Icon on Strapi
 * - Note that icons are just normal images uploaded to strapi in SVG format
 * - This is a separate component because Gatsby Image can't be used with SVGs
 *   and also allows consistent styling of icons
 * - At some point we may want to embed this directly in the html so we can style it
 * - Note when using this component, make sure width and height are defined
 *   after sending in destructured props (...icon) to override the image width and height
 *   properties returned via the Strapi API.
 */
const Icon = ({ url, alternativeText, size, ...rest }) => {
  // Size prop from Strapi is removed so it doesn't interfere with styled-system
  return (
    <Box {...rest}>
      {url && <StyledImg src={url} alt={alternativeText || ""} />}
    </Box>
  )
}

export default Icon

Icon.strapiProps = {
  url: PropTypes.string.isRequired,
  alternativeText: PropTypes.string.isRequired,
}

Icon.propTypes = {
  ...Icon.strapiProps,
  ...Box.propTypes,
}

export const query = graphql`
  fragment Icon on Strapi_UploadFile {
    url
    alternativeText
  }
`
