import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import FlexGrid from "../atoms/FlexGrid"
import Button from "../atoms/Button"
import Box from "../atoms/Box"

import SectionTitle from "../molecules/SectionTitle"

const ButtonGrid = ({ section, sectionTitle, buttons, flexGrid }) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FlexGrid {...flexGrid} gutter={6}>
        {buttons.map((button, idx) => (
          <Box key={idx}>
            <Button {...button} height={9} width="100%" />
          </Box>
        ))}
      </FlexGrid>
    </Section>
  )
}

ButtonGrid.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.strapiProps)).isRequired,
}

ButtonGrid.propTypes = {
  ...ButtonGrid.strapiProps,
}

export default ButtonGrid

export const query = graphql`
  fragment ButtonGrid on Strapi_ComponentSectionsButtonGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    buttons {
      ...Button
    }
    flexGrid {
      ...FlexGrid
    }
  }
`
