import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Indicator from "../atoms/Indicator"
import StandardPadding from "../atoms/StandardPadding"

const ChildWrapper = styled.div`
  transform: translateX(calc(var(--offset) * 70%))
    ${(props) => (props.active ? "" : "scale(0.83)")};
  transition: all 0.4s ease;
  z-index: ${(props) => (props.active ? 2 : 1)};
  transition-property: transform opacity;
  margin: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  &:hover {
    ${(props) =>
      props.active && props.enableHover
        ? "transform: translateX(0) scale(1.05);"
        : null}
  }
`

const Container = styled(Flex)`
  ${"" /* overflow: hidden; */}
  display: grid;
  > div {
    grid-area: 1 / -1;
  }
`

const DraggableCarousel = ({ children, rotateSpeed, numItems }) => {
  const [items, setItems] = useState(
    React.Children.map(children, (child, i) => ({ child, key: i }))
  )
  const [activeIdx, setActiveIdx] = useState(0)
  // use indicatorIdx for indicator if not null, otherwise use activeIdx
  // used to prevent intermediate activeIdxs from changing opacity
  // when transitioning between two activeIdxs
  const [indicatorIdx, setIndicatorIdx] = useState(null)
  const [enableHover, setEnableHover] = useState(true)
  const inSwipe = useRef(false)
  const mouseSwipe = useRef(false)
  const swipeStartPos = useRef(null)

  useEffect(() => {
    setItems(React.Children.map(children, (child, i) => ({ child, key: i })))
  }, [children])

  const length = items.length

  function moveCarousel(direction) {
    if (direction === "RIGHT") {
      setActiveIdx(activeIdx + 1)
    } else {
      setActiveIdx(activeIdx - 1)
    }
  }

  function handleSwipeLogic(x) {
    if (x > swipeStartPos.current + 20) {
      inSwipe.current = true
      moveCarousel("LEFT")
    } else if (x < swipeStartPos.current - 20) {
      inSwipe.current = true
      moveCarousel("RIGHT")
    }
  }

  function handleTouchStart(e) {
    swipeStartPos.current = e.nativeEvent.targetTouches[0].clientX
  }

  function handleTouchMove(e) {
    if (inSwipe.current) return

    const x = e.nativeEvent.targetTouches[0].clientX
    handleSwipeLogic(x)
  }

  function handleTouchEnd() {
    inSwipe.current = false
  }

  function handleMouseDown(e) {
    swipeStartPos.current = e.clientX
    mouseSwipe.current = true
  }

  function handleMouseMove(e) {
    if (!mouseSwipe.current || inSwipe.current) return
    const x = e.clientX
    handleSwipeLogic(x)
  }

  function handleMouseClick(e) {
    if (inSwipe.current) {
      e.preventDefault()
    }
    inSwipe.current = false
  }

  function handleMouseUp(e) {
    if (inSwipe.current) {
      e.preventDefault()
    }
    mouseSwipe.current = false
  }

  let effectiveActiveIdx
  if (activeIdx < 0) {
    if (activeIdx % length === 0) {
      effectiveActiveIdx = 0
    } else {
      effectiveActiveIdx = length + (activeIdx % length)
    }
  } else {
    effectiveActiveIdx = activeIdx % length
  }

  function intermediateTransitionPromise(i, targetIdx) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          setActiveIdx(i)
          resolve()
        },
        i === effectiveActiveIdx ? 0 : 50
      )
    })
  }

  /**
   * Create chain of promises that will cycle through all
   * the items between an old and new active index
   */
  function onChange(targetIdx) {
    let promises = []

    // keeps from looping through items twice
    if (effectiveActiveIdx >= length) targetIdx = targetIdx + length

    // we don't want to set the active indicator based on the activeIdx
    // during the transition, so here we just set it to the targetIdx
    setIndicatorIdx(targetIdx)
    if (targetIdx > effectiveActiveIdx) {
      for (let i = effectiveActiveIdx; i <= targetIdx; i++) {
        promises.push(intermediateTransitionPromise.bind(null, i, targetIdx))
      }
    } else {
      for (let i = effectiveActiveIdx; i >= targetIdx; i--) {
        promises.push(intermediateTransitionPromise.bind(null, i, targetIdx))
      }
    }
    let promise = promises[0]()
    for (let i = 1; i < promises.length; i++)
      promise = promise.then(promises[i])
    // once we reach the target index, we can go back to using
    // the activeIdx to set the active indicator
    promise.then(() => setIndicatorIdx(null))
  }

  useEffect(
    function disableHoverWhileRotating() {
      setEnableHover(false)
      let timeout = setTimeout(() => {
        setEnableHover(true)
      }, 400)
      return () => {
        clearTimeout(timeout)
      }
    },
    [activeIdx, setEnableHover]
  )

  useEffect(
    function rotateTimeout() {
      if (rotateSpeed) {
        let timeout = setTimeout(() => {
          moveCarousel("RIGHT")
        }, rotateSpeed * 1000)
        return () => {
          clearTimeout(timeout)
        }
      }
    },
    [activeIdx, rotateSpeed]
  )

  return (
    <Box pt="40px" mt="-40px" overflow="hidden">
      {/* add the padding and remove margin to not cut off hover scale */}
      <Flex justifyContent="center" alignItems="center">
        <Container
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onClick={handleMouseClick}
        >
          {items.map(({ child, key }, i) => {
            // get corresponding index from activeIdx
            // converts to corresponding value between 0 and length - 1
            let offset = i - effectiveActiveIdx
            if (offset < -numItems / 2) {
              offset = i + numItems - effectiveActiveIdx
            } else if (offset > numItems / 2) {
              offset = i - numItems - effectiveActiveIdx
            }
            offset = Math.max(Math.min(offset, 2), -2)
            return (
              <ChildWrapper
                active={i === effectiveActiveIdx}
                enableHover={i === effectiveActiveIdx && enableHover}
                visible={Math.abs(offset) < 2}
                style={{ "--offset": offset }}
                key={child.key}
              >
                {child}
              </ChildWrapper>
            )
          })}
        </Container>
      </Flex>
      <StandardPadding py={0}>
        <Flex overflow="hidden" justifyContent="center" mt={6}>
          {[...Array(numItems)].map((_item, idx) => (
            <Indicator
              key={idx}
              maxWidth={`calc(100% / ${numItems})`}
              onClick={() => {
                onChange(idx)
              }}
              active={
                indicatorIdx !== null
                  ? indicatorIdx % numItems === idx
                  : effectiveActiveIdx % numItems === idx
              }
            />
          ))}
        </Flex>
      </StandardPadding>
    </Box>
  )
}

export default DraggableCarousel
