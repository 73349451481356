import React from "react"
import MuiTextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"
import InputLabel from "../atoms/InputLabel"
import styled from "styled-components"
import { space, layout, border, compose } from "styled-system"
import css from "../../lib/styled-system/css"
import { splitProps, shouldForwardProp } from "../../lib/styled-system/helpers"

const DefaultWrapper = styled("div").withConfig({ shouldForwardProp })`
  width: 100%;
  ${css({ fontFamily: "body", color: "text" })}
  ${compose(space, layout)}
  & .MuiInput-root {
    font-family: inherit;
    color: inherit;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    padding: 8px 10px;
    ${css({
      fontSize: 1,
      borderRadius: "sm",
      borderColor: "border.1",
    })}
    ${border}
  }
  & .MuiInput-root.Mui-error {
    border-color: #f44336;
  }
  & .MuiFormHelperText-root {
    font-family: inherit;
  }
`

const FilledWrapper = styled("div").withConfig({ shouldForwardProp })`
  & .MuiTextField-root {
    background-color: rgba(0, 0, 0, 0.01);
    ${css({ fontFamily: "body", color: "text" })}
    ${compose(space, layout)}
  }
  & .MuiInputBase-root {
    ${css({ fontSize: 1 })}
    height: 100%;
  }
  & .MuiFilledInput-root {
    ${css({ borderRadius: "sm" })}
    ${border}
  }
  & .MuiInputBase-root,
  & .MuiInputLabel-root,
  & .MuiFormLabel-root.Mui-focused {
    font-family: inherit;
    color: inherit;
  }
  & .MuiFormHelperText-root {
    font-family: inherit;
  }
  & .MuiFormLabel-shrink {
    opacity: 0.7;
  }
`

const TextField = (props) => {
  let { space, layout, border, rest: textFieldProps } = splitProps(props, [
    "space",
    "layout",
    "border",
  ])

  if (!props.variant) {
    // Basic text field without a variant
    // This is custom to avoid the shrinking text animation used in Material UI
    const {
      id,
      name,
      label,
      value,
      onChange,
      error,
      helperText,
      required,
      ...rest
    } = textFieldProps

    return (
      <DefaultWrapper {...space} {...layout} {...border}>
        <InputLabel htmlFor={id} error={error} required={required}>
          {label}
        </InputLabel>
        <Input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          error={error}
          required={required}
          aria-describedby={`${id}-helper-text`}
          disableUnderline
          {...rest}
        />
        {helperText && (
          <FormHelperText id={`${id}-helper-text`} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </DefaultWrapper>
    )
  }

  return (
    <FilledWrapper {...space} {...layout} {...border}>
      <MuiTextField
        InputProps={{ disableUnderline: true }}
        size="small"
        {...textFieldProps}
      />
    </FilledWrapper>
  )
}

export default TextField
