import React from "react"
import { graphql } from "gatsby"
import Page from "../components/page/Page"
import "normalize.css"

const PageTemplate = ({ data }) => <Page data={data} />

export default PageTemplate

export const query = graphql`
  query PageById($pageId: ID!, $siteId: ID!) {
    strapi {
      site(id: $siteId) {
        ...Site
        pages(where: { id: $pageId }) {
          ...Page
        }
      }
    }
  }
`
