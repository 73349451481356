import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Image from "../utils/Image"
import Link from "../utils/Link"

import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title } from "../atoms/Typography"

const StyledTitle = styled(Title)`
  transition: color 0.4s ease;
`

const StyledCard = styled(Card)`
  transition: background 0.4s ease;
`

const StyledProportionalBox = styled(Card)`
  transition: transform 0.4s ease;
`

const NameBox = ({ name, sectionTheme, hover, ...rest }) => {
  return (
    <Box className="name-box" {...rest}>
      <StyledTitle color={hover ? "primary" : "text"} variant="h3" mb={2}>
        {name}
      </StyledTitle>
    </Box>
  )
}

const CompanyCard = ({
  name,
  sectionTheme,
  link,
  square,
  image,
  card,
  imageLocation,
}) => {
  const [hover, setHover] = React.useState(false)

  const innerRight = imageLocation === "innerRight"
  let flexStyles
  let nameBoxStyles
  let cardStyles
  switch (imageLocation) {
    case "innerRight":
      flexStyles = {
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        py: 7,
      }
      nameBoxStyles = {
        px: 6,
      }
      break
    case "top":
    default:
      flexStyles = {
        flexDirection: "column",
      }
      nameBoxStyles = {
        p: 4,
      }
  }

  const innerRightHover = hover && innerRight

  return (
    <Link
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      optional
      {...link}
    >
      <StyledCard
        {...cardStyles}
        {...card}
        bg={innerRightHover ? "transparent" : card.bg}
        boxShadow={innerRightHover ? "none" : card.boxShadow}
        theme={innerRightHover ? sectionTheme : card.theme}
      >
        <Flex {...flexStyles}>
          <StyledProportionalBox
            boxShadow={innerRight && "sm"}
            position="relative"
            style={
              innerRight
                ? hover
                  ? {
                      transform: "translateX(0)",
                    }
                  : {
                      transform: "translateX(10%)",
                    }
                : null
            }
            ratio={innerRight ? 0.75 : square ? 1 : 0.65}
          >
            <Image {...image} style={{ height: "100%" }} />
          </StyledProportionalBox>
          <NameBox
            sectionTheme={sectionTheme}
            hover={hover && innerRight}
            name={name}
            width="100%"
            transitionDuration="fast"
            transitionTimingFunction="ease"
            {...nameBoxStyles}
          />
        </Flex>
      </StyledCard>
    </Link>
  )
}

export default CompanyCard

CompanyCard.strapiProps = {
  name: PropTypes.string.isRequired,
  link: PropTypes.shape(Link.strapiProps),
  image: PropTypes.object,
  tag: PropTypes.object,
}

CompanyCard.propTypes = {
  ...CompanyCard.strapiProps,
  card: PropTypes.shape(Card.strapiProps),
  imageLocation: PropTypes.oneOf(["default", "top", "innerRight"]),
}

export const query = graphql`
  fragment CompanyCard on Strapi_ComponentMoleculesCompanyCard {
    name
    link {
      ...Link
    }
    tags {
      name
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
