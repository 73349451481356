import React from "react"
import { color, layout, compose } from "styled-system"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import styled from "styled-components"

import css from "../../lib/styled-system/css"
import { Title } from "../atoms/Typography"
import Box from "../atoms/Box"
import Card from "../atoms/Card"
import ExpandMoreIcon from "../icons/Caret"

export const Border = styled.div`
${compose(color)}
  display: ${(props) => (props.shouldDisplay ? "none" : "block")};
  transition: all 0.01s ease-in-out;
  position: ${(props) => (props.fullWidthDivider ? "absolute" : "relative")};
  opacity: 0.75;
  width: 100%;
  height: 2px;
  left: 0;
  z-index: 100;
`

const Accordion = styled(MuiAccordion)`
  ${compose(layout)}
  &.MuiAccordion-root {
    background-color: transparent;
    transition: none;
    box-shadow: none;
    &.Mui-expanded {
      margin: 0px;
    }
    &:not(:last-child) {
      border-bottom: 0;
    }
    &:before {
      display: none;
    }
    & .MuiAccordionSummary-root {
      ${css({ px: 6, pb: 3, mt: 6 })};

      &.Mui-expanded {
        border-bottom-width: 0px;
        ${css({ pb: 0, mb: -4 })};
      }
      & .MuiIconButton-edgeEnd {
        ${css({ mt: -5 })};
      }
    }
    & .MuiAccordionSummary-content {
      ${css({ m: 0, mt: -3 })};
    }
    & .MuiAccordionDetails-root {
      padding: 0px;
    }
  }
`
const AccordionSummary = styled(MuiAccordionSummary)`
  ${compose(layout)}
`

const AccordionDetails = styled(MuiAccordionDetails)`
  ${compose(layout)}
`

const Accordions = ({
  label,
  items,
  toggleMany,
  expandIcon,
  labelStyles,
  contentStyles,
  card,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState("") //React.useState("panel1")
  const [expandedMany, setExpandedMany] = React.useState({}) //React.useState({panel1:true})

  const handleChange = (panel) => (event, newExpanded) => {
    if (toggleMany) {
      // const updatedAccordion = newExpanded ? true : false
      const newState = { ...expandedMany, [panel]: newExpanded }
      setExpandedMany(newState)
    } else {
      setExpanded(newExpanded ? panel : false)
    }
  }
  if (!items) return null

  const border = (
    <Border
      bg="border.0"
      fullWidthDivider={false}
      shouldDisplay={card.useCard}
    />
  )
  return (
    <Box>
      {items.map(({ label, content }, idx) => {
        let id = `panel${idx + 1}`
        return (
          <Box my={card?.useCard ? 4 : 0}>
            <Card height="100%" {...rest} {...card}>
              {border}
              <Accordion
                square
                expanded={expanded === id || expandedMany[id] === true}
                onChange={handleChange(id)}
                {...rest}
              >
                <AccordionSummary
                  expandIcon={
                    expandIcon || (
                      <ExpandMoreIcon
                        width="25px"
                        height="25px"
                        strokeWidth="1.2"
                        color="border.0"
                      />
                    )
                  }
                  aria-controls={`${id}d-content`}
                  id={`${id}d-header`}
                  style={labelStyles}
                >
                  <Title mb={3}>{label}</Title>
                </AccordionSummary>

                <AccordionDetails style={contentStyles}>
                  {content}
                </AccordionDetails>
              </Accordion>
            </Card>
          </Box>
        )
      })}
      {border}
    </Box>
  )
}

export default Accordions
